'use strict';
import Chart from "chart.js/auto";
import {get} from "app/request";
import {$I} from "app/dom-util";
const graficaPrecioToken = () => {
  const canvasPrecioToken = $I("graficaPrecioToken");
  if (canvasPrecioToken) {
    const graficaPrecioToken = new Chart(canvasPrecioToken, {
      options: {
        plugins: {
          tooltip: {
            callbacks: {
              label: function (context) {
                let label = context.dataset.label || '';

                if (label) {
                  label += ': ';
                }
                if (context.parsed.y !== null) {
                  let cantidad = (context.datasetIndex !== 0 ? context.raw.tokens : context.parsed.y)
                  label += new Intl.NumberFormat('es-ES').format(cantidad);
                  label += (context.datasetIndex !== 0 ? " JotaM" : " USDT");
                }
                return label;
              },
              title: function (context) {
                return "Periodo " + context[0].label;
              }
            }
          }
        }
      }
    });
    get("/panel/datos-precio-token").then(result => {
      const precios = (!result.json.preciosToken ? [] : result.json.preciosToken);
      const dataset = {
        type: "line",
        options: {
          order: 3,
          plugins: {
            legend: {
              display: false
            }
          },
          scales: {
            x: {
              display: true,
              title: {
                display: true,
                text: "Periodos"
              }
            },
            y: {
              display: true,
              title: {
                display: true,
                text: "Valor"
              },
              ticks: {
                callback: function (value, index, values) {
                  return '$' + value;
                }
              }
            }
          }
        },
        label: 'Precio',
        backgroundColor: 'rgba(78, 115, 223)',
        data: precios,
        borderColor: 'rgba(78, 115, 223)',
        fill: false,
        tension: 0.4
      };

      for (let key of precios.keys()) {
        graficaPrecioToken.data.labels.push((key + 1));
      }
      graficaPrecioToken.data.datasets.push(dataset);
      get("/panel/datos-inversion").then(result => {
        const tokensComprados = (!result.json.tokensComprados ? [] : result.json.tokensComprados);
        const tokensVendidos = (!result.json.tokensVendidos ? [] : result.json.tokensVendidos);

        let compras = [];
        tokensComprados.forEach((tk) => {
          compras.push({x: tk.ronda_id, y: tk.ronda.precioToken, tokens: tk.tokens});
        });

        let ventas = [];
        tokensVendidos.forEach((tk) => {
          ventas.push({x: tk.ronda_id, y: tk.ronda.precioToken, tokens: tk.tokens});
        });

        const dataset1 = {
          options: {
            order: 1,
            scales: {
              x: {
                type: 'linear',
                position: 'bottom'
              }
            }
          },
          type: 'scatter',
          label: 'Compras',
          data: compras,
          backgroundColor: 'rgba(28, 200, 138)',
          pointRadius: 7,
          pointHoverRadius: 8
        };
        const dataset2 = {
          options: {
            order: 2,
            scales: {
              x: {
                type: 'linear',
                position: 'bottom'
              }
            }
          },
          type: 'scatter',
          label: 'Ventas',
          data: ventas,
          backgroundColor: 'rgb(255, 99, 132)',
          pointRadius: 7,
          pointHoverRadius: 8

        };
        graficaPrecioToken.data.datasets.push(dataset1);
        graficaPrecioToken.data.datasets.push(dataset2);
        graficaPrecioToken.update();
      }).catch(err => {
      });
    }).catch(err => {
    });
  }
};
export default function graficas() {
  graficaPrecioToken();
};
