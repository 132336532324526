'use strict';
import {$QSA} from "app/dom-util";
import {Tooltip} from 'bootstrap';

export default function clipboard() {
  $QSA(".clipboard-copy").forEach(el => {
    var tool = new Tooltip(el);
    el.addEventListener('mouseleave', function () {
      tool.hide();
      el.setAttribute('data-bs-original-title', '');
    });
    el.addEventListener("click", e => {
      e.preventDefault();
      const padre = el.closest('.input-group');
      var input = padre.querySelector('input.form-control');
      navigator.clipboard.writeText(input.value).then(
        () => {
          var tool = Tooltip.getInstance(el);
          el.setAttribute('data-bs-original-title', 'Copiado!');
          tool.show();
        }, 
        () => {
          var tool = Tooltip.getInstance(el);
          el.setAttribute('data-bs-original-title', 'Error!')
          tool.show();
        }
      );
    })
  });
};