import {crearElemento, $I} from "app/dom-util";
import BigNumber from "bignumber.js";
import MetaMask from "app/web3/metamask"
import Config from "app/web3/config"

export default async function conversor() {
  BigNumber.config({ ROUNDING_MODE: BigNumber.ROUND_DOWN })
  const inputUsdtCompra = $I("cantidad-moneda-compra");
  const inputTkCompra = $I("cantidad-token-compra");
  const inputUsdtVenta = $I("cantidad-moneda-venta");
  const inputTkVenta = $I("cantidad-token-venta");
  const inputPrecioToken = $I("valor-token-actual");
  const metaMask = new MetaMask();
  const decimalesUsdt = parseInt(await metaMask.decimales(Config.usdt.contrato, Config.usdt.abi));
  const decimalesToken = parseInt(await metaMask.decimales(Config.token.contrato, Config.token.abi));

  if (inputUsdtCompra && inputTkCompra && inputPrecioToken) {
    const precioToken=BigNumber(inputPrecioToken.dataset.precio);
    inputUsdtCompra.addEventListener("input", async (e) => {
      if(inputUsdtCompra.value!==inputUsdtCompra.dataset.last && !/[.,][0]+$/.test(inputUsdtCompra.value)){
        inputUsdtCompra.value = BigNumber(inputUsdtCompra.value).dp(decimalesUsdt);
        inputUsdtCompra.dataset.last=inputUsdtCompra.value;
      }
      inputTkCompra.value = BigNumber(inputUsdtCompra.value).div(precioToken).dp(decimalesToken);
    });
    inputTkCompra.addEventListener("input", async (e) => {
      if(inputTkCompra.value!==inputTkCompra.dataset.last && !/[.,][0]+$/.test(inputTkCompra.value)){
        inputTkCompra.value = BigNumber(inputTkCompra.value).dp(decimalesToken);
        inputTkCompra.dataset.last=inputTkCompra.value;
      }
      inputUsdtCompra.value = BigNumber(inputTkCompra.value).times(precioToken).dp(decimalesUsdt);
      
    });
  }
  if (inputUsdtVenta && inputTkVenta && inputPrecioToken) {
    const precioToken=BigNumber(inputPrecioToken.dataset.precio)
    inputUsdtVenta.addEventListener("input", async (e) => {
      if(inputUsdtVenta.value!==inputUsdtVenta.dataset.last && !/[.,][0]+$/.test(inputUsdtVenta.value)){
        inputUsdtVenta.value = BigNumber(inputUsdtVenta.value).dp(decimalesUsdt);
        inputUsdtVenta.dataset.last=inputUsdtVenta.value;
      }
      inputTkVenta.value = BigNumber(inputUsdtVenta.value).div(precioToken).dp(decimalesToken);
    });
    inputTkVenta.addEventListener("input", async (e) => {
      if(inputTkVenta.value!==inputTkVenta.dataset.last && !/[.,][0]+$/.test(inputTkVenta.value)){
        inputTkVenta.value = BigNumber(inputTkVenta.value).dp(decimalesToken);
        inputTkVenta.dataset.last=inputTkVenta.value;
      }
      inputUsdtVenta.value = BigNumber(inputTkVenta.value).times(precioToken).dp(decimalesUsdt);
    });
  }
};