'use strict';
import $ from "jquery";

import "app/bootstrap";

import "app/bootstrap-table"

import activaLoginWeb3 from 'app/web3/login';
import activaOperaciones from 'app/web3/operacion';
import calculaValorTokens from 'app/web3/panel';
import {post} from 'app/request';
import t from 'app/traductor';
import {mostrarError, mostrarMensaje} from "app/mensajes";
import activaConversor from "app/public/conversor";
import graficas from "app/public/graficas";
import activaTogglePassword from "app/toggle-password";
import activaClipboard from "app/clipboard";
import activaSelectorTipoPerfil from "app/perfil";
import activaConfirmaciones from "app/confirmaciones";
import activaSlimSelect from 'app/slim-select'
import activaSortables from 'app/sortables'

//import "app/template/archivos/js/sb-admin-2.min.js"
import template from 'app/template'
activaLoginWeb3();
activaOperaciones();
calculaValorTokens();
activaConversor();
graficas();
activaTogglePassword();
activaClipboard();
activaSelectorTipoPerfil();
activaConfirmaciones();
activaSlimSelect();
activaSortables();
